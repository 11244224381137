import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactForm from '../components/contactForm'

import {
  projectBody
} from '../components/layout.module.scss'
export default function OurServices() {
  return (
    <Layout pageTitle="What We Do">
    <Seo
      title="Our Services"
      description="What we do, as a creative digital agency based in Los Angeles, we are dedicated to helping businesses achieve their goals through innovative and effective digital solutions" 
    />
      <div className={projectBody}>
        <div className='grid grid-cols-1 lg:grid-cols-12 gap-14'>
              <div className='lg:col-span-8 pr-10'>
                  <p className='mt-3 mb-5'>We craft custom websites that blend cutting-edge tech with AI-driven innovation. Our seasoned team works hand-in-hand with you to develop digital strategies that truly reflect your brand. The result? A web presence that's not just visually striking, but also technically sound. Join forces with us to elevate your business and carve out your niche in the digital world.</p>
                  <h2 className='text-xl text-black font-semibold'>Shopify and eCommerce Development</h2>
                  <p className='mt-3 mb-5'>We excel in the design and development of custom eCommerce platforms for enterprises of all scales. From emerging startups to established corporations, our expert team possesses the acumen to craft a distinctive online presence that resonates with your target demographic. Leveraging a suite of advanced technologies, including Shopify, we integrate custom features and unique selling propositions into your eCommerce ecosystem, complemented by sophisticated AI-integrated applications that elevate your platform's functionality.</p>
                  <br />
                  <h2 className='text-xl text-black font-semibold'>Web Design and Development</h2>
                  <p className='mt-3 mb-5'>Our web design and development services are tailored to empower businesses across all sectors to achieve their strategic objectives. Our seasoned team of designers and developers collaborates to create bespoke, high-performance websites that effectively engage your target audience and distinguish your brand in the digital landscape. We harness a diverse array of cutting-edge technologies, including Shopify, ReactJS, React Native, NodeJS, NextJS, Ruby on Rails, Headless Content Management Systems (CMS), and Amazon Web Services, ensuring your digital presence is not only visually compelling but also technically robust.</p>
                  <br />
                  <h2 className='text-xl text-black font-semibold'>GenAI Development Services</h2>
                  <p className='mt-3 mb-5'>We're in the business of turning AI potential into real-world success. Our team doesn't just work with GenAI – we breathe it. From developing and fine-tuning Large Language Models to crafting the perfect prompts, we're here to make AI work for you.</p>
                  <p>Imagine having an AI solution that truly gets your business. That's what we deliver. We dive deep into cutting-edge tech like Retrieval Augmentation Generation (RAG) and harness it to solve your unique challenges. Our machine learning algorithms don't just generate content; they create insights that can revolutionize how you operate.</p>
                  <p>Ready to lead the pack in your industry? Let's team up. We'll help you tap into AI's full potential, turning futuristic concepts into your competitive edge.</p>
                  <p>Here's what we bring to the table:</p>
                  <ul className='list-none'>
                    <li><strong>Customized AI Solutions:</strong> We develop custom AI solutions precisely aligned with your business needs. Each system is engineered from the ground up, addressing your specific challenges and objectives. Our approach ensures that every AI solution we deliver is as unique as the business it serves.</li>
                    <li><strong>Large Language Model Development:</strong> We specialize in the creation, training, and optimization of Large Language Models (LLMs) tailored for diverse business applications. Our rigorous approach ensures peak performance and highly relevant outputs.</li>
                    <li><strong>Advanced Prompt Engineering:</strong> Leveraging our deep expertise in prompt engineering, we fine-tune AI models to generate precise, high-quality content that aligns seamlessly with your specific business needs.</li>
                    <li><strong>Advanced AI Architectures:</strong> Our solutions incorporate state-of-the-art architectures such as Retrieval Augmentation Generation (RAG) and Generative AI (GenAI), significantly enhancing the capabilities and efficiency of our AI implementations.</li>
                    <li><strong>Intelligent Content Generation:</strong> Our sophisticated machine learning algorithms excel at producing content based on user inputs, offering valuable insights and streamlining complex processes.</li>
                    <li><strong>AI-Driven Business Transformation:</strong> Collaborate with us to harness the transformative power of AI. We enable you to spearhead your industry with innovative advancements that drive operational excellence and foster strategic growth.</li>
                  </ul>
                  <br />
                  <h2 className='text-xl text-black font-semibold'>Generative AI SEO</h2>
                  <p className='mt-3 mb-5'>Harness the power of GenAI services with our Generative AI SEO services. We transform your SEO strategy to deliver unparalleled results. Our expertise in Google's Search Generative Experience (SGE) and generative AI ensures your content ranks higher, attracts more traffic, and drives engagement, positioning your business at the forefront of digital innovation.</p>
                  <ul className='list-none'>
                    <li><strong>AI-Enhanced Search Result Organization:</strong> We develop AI-driven results pages that intelligently categorize search outcomes under GenAI-generated headings, providing a diverse array of perspectives and content formats.</li>
                    <li><strong>Advanced Visual Search Integration:</strong> We optimize your video content for cutting-edge visual search functionalities, enabling GenAI search to generate comprehensive AI Overviews and enhance short-form video content.</li>
                    <li><strong>Strategic Structured Data Implementation:</strong> We meticulously organize your website data using structured data markups, providing crucial context about your offerings, including products, services, locations, FAQs, and customer reviews. This ensures your web pages are fully optimized for Generative AI Search algorithms.</li>
                    <li><strong>Dynamic Content Creation Strategy:</strong> We invest in producing timely, relevant content that aligns precisely with user search intent, bolstering your rankings in AI-generated search results. Our AI-powered tools efficiently transform long-form videos into high-impact, viral-ready clips.</li>
                    <li><strong>GenAI Search and SGE Optimization:</strong> We enhance your overall search performance and SEO rankings by leveraging structured data (Schema.org) to systematically organize your website's information, preparing it for Google's Search Generative Experience (SGE) and other GenAI search platforms.</li>
                    <li><strong>Strategic Schema Utilization:</strong> We employ schema markup strategically to amplify your website's visibility at the individual page level, facilitating the categorization of search results under AI-generated headlines for improved user engagement.</li>
                  </ul>
                  <br />
                  <h2 className='text-xl text-black font-semibold'>AI Prompt Engineering Services</h2>
                  <p className='mt-3 mb-5'>Our AI Prompt Engineering Services are designed to maximize the potential of artificial intelligence for your business. Through the development of precise and strategically crafted prompts, we ensure AI models generate high-quality, relevant, and impactful outputs tailored to your specific objectives.</p>
                  <ul className='list-none'>
                    <li><strong>Custom AI Solutions:</strong> We engineer prompts tailored to your unique business applications, optimizing AI model performance for superior results.</li>
                    <li><strong>Enhanced Operational Efficiency:</strong> Our expertise in prompt engineering significantly improves the efficiency and accuracy of AI models, minimizing errors and boosting productivity.</li>
                    <li><strong>Elevated Customer Experiences:</strong> We leverage advanced AI tools to refine AI interactions, enhancing customer service and fostering personalized experiences that transform satisfaction into enduring customer loyalty.</li>
                    <li><strong>Innovative Content Generation:</strong> We empower your AI systems to produce premium content across various formats, from compelling marketing copy to comprehensive reports, optimizing resource allocation.</li>
                    <li><strong>Strategic Competitive Advantage:</strong> By employing cutting-edge prompt engineering techniques, we position your business at the forefront of AI innovation, maximizing the return on your AI investments.</li>
                  </ul>
                  <br />
                  <h2 className='text-xl text-black font-semibold'>Digital Marketing & SEO</h2>
                  <p className='mt-3 mb-5'>We offer comprehensive digital marketing and SEO solutions designed to amplify your brand's reach and enhance your online visibility. Our services encompass sophisticated Facebook Business Manager advertising campaigns, enabling precise targeting of your ideal audience across mobile and desktop platforms based on demographics, interests, and behavioral factors. Additionally, our SEO optimization strategies are crafted to elevate your organic search rankings on Google, facilitating seamless discovery by potential customers searching for your products or services.</p>
                  <br />
                  <h2 className='text-xl text-black font-semibold'>Corporate Branding</h2>
                  <p className='mt-3 mb-5'>We specialize in cultivating robust and impactful brand identities in the digital sphere. Leveraging our profound expertise and keen understanding of contemporary internet design trends, we collaborate with you to construct, design, and establish a compelling brand presence for your enterprise. Our team of seasoned designers and marketing strategists is adept at developing a cohesive and distinctive brand image that commands attention in today's competitive digital marketplace.</p>
                  <br />
              </div>
              <div className='lg:col-span-4'>
                <div className="float-right sticky top-24 inline-block">
                  <ContactForm />
                </div>
              </div>
        </div>
      </div>
    </Layout>
  )
}